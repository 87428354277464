document.addEventListener('DOMContentLoaded', function() {

    $('.images-gallery .images-gallery-holder').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        arrows: true,
        infinite: true,
        prevArrow: $('.images-gallery .prev'),
        nextArrow: $('.images-gallery .next'),
        appendDots: $('.images-gallery .nav'),
        centerMode: true,
        centerPadding: '0px',
        variableWidth: false
    });

});
