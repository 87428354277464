document.addEventListener('DOMContentLoaded', function() {

    $('.dentristy-images .dentristy-images-holder').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        arrows: true,
        infinite: true,
        prevArrow: $('.dentristy-images .prev'),
        nextArrow: $('.dentristy-images .next'),
        appendDots: $('.dentristy-images .nav'),
        centerMode: true,
        centerPadding: '0px',
        variableWidth: false
    });

});
