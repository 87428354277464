import indexStyles from '../sass/index.scss';
let commons = require('./utils/commons');
import childrenDentristyImagesJS from './includes/children_dentristy_images.js';
import galleryJS from './includes/gallery.js';
import mainSlider1IMG from '../img/main-slider-1.png';
import companyPoints1IMG from '../img/company-points-1.png';
import companyPoints2IMG from '../img/company-points-2.png';
import companyPoints3IMG from '../img/company-points-3.png';
import companyPoints4IMG from '../img/company-points-4.png';
import companyPoints5IMG from '../img/company-points-5.png';
import companyPoints6IMG from '../img/company-points-6.png';
import companyPoints7IMG from '../img/company-points-7.png';
import companyPoints8IMG from '../img/company-points-8.png';
import aboutMainBkgIMG from '../img/about-main-bkg.png';
import aboutMainBkg2IMG from '../img/about-main-bkg-2.png';
import service1IMG from '../img/service-1.png';
import service2IMG from '../img/service-2.png';
import service3IMG from '../img/service-3.png';
import service4IMG from '../img/service-4.png';
import service5IMG from '../img/service-5.png';
import service6IMG from '../img/service-6.png';
import service7IMG from '../img/service-7.png';
import service8IMG from '../img/service-8.png';
import service9IMG from '../img/service-9.png';
import licence1IMG from '../img/licence-1.png';
import personel1IMG from '../img/personel-1.png';
import personel2IMG from '../img/personel-2.png';
import personel3IMG from '../img/personel-3.png';
import personel4IMG from '../img/personel-4.png';
import personel5IMG from '../img/personel-5.png';
import personel6IMG from '../img/personel-6.png';
import reviewAuthorIMG from '../img/review-author.png';
import reviewVideoIMG from '../img/review-video.png';
import medicalInsuranceIMG from '../img/medical-insurance.png';
import insuranceCоmpany1IMG from '../img/insurance-company-1.png';
import insuranceCоmpany2IMG from '../img/insurance-company-2.png';
import insuranceCоmpany3IMG from '../img/insurance-company-3.png';
import insuranceCоmpany4IMG from '../img/insurance-company-4.png';
import insuranceCоmpany5IMG from '../img/insurance-company-5.png';
import ourWorks1IMG from '../img/our-works-1.png';
import ourWorks2IMG from '../img/our-works-2.png';
import ourWorks3IMG from '../img/our-works-3.png';
import ourWorks4IMG from '../img/our-works-4.png';
import childrenDentristy1IMG from '../img/children-dentristy-1.png';
import childrenDentristy2IMG from '../img/children-dentristy-2.png';
import childrenDentristy3IMG from '../img/children-dentristy-3.png';
import gallery1IMG from '../img/gallery-1.png';
import gallery2IMG from '../img/gallery-2.png';
import gallery3IMG from '../img/gallery-3.png';
import mapIMG from '../img/map.png';
